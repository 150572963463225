.container {
  margin: 0 auto;
  left: 0;
  right: 0;
  /* max-width: 2000px; */
  background: #f5f0ea;
  background-repeat: no-repeat;
  background-size: contain, cover;
}

.section {
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;
  max-width: 1500px;
  margin: auto;
}

.header,
.subheader {
  margin-bottom: 1rem;
  text-align: center;
}

.header {
  font-family: "Futura Bold Oblique", arial;
  font-weight: 700;
  font-size: 3rem;
  color: #e5080b;
}

.subheader {
  font-size: 1.2rem;
  align-self: center;
  margin-bottom: 2rem;
  font-family: "Futura", arial;
  font-weight: 500;
}

.separateRough {
  background-image: url("../../assets/imgs/seperate.png");
  background-color: #f5f0ea;
  height: 160px;
  background-position-y: 0;
  background-size: contain;
  background-repeat: round no-repeat;
  transform: rotate(180deg);
}

.TechWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Adjust this value to set space between items */
}

.techItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 200px; /* Adjust this value to match your desired width */
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
  padding: 10px;
  border-color: gray;
}

.techItem:hover {
  border-color: #e5080b; /* Adjust this color to match your design */
}

.techName {
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
}

.techItem img {
  margin-bottom: 10px;
  max-width: 100%;
  height: auto;
}

.techInfo {
  color: black;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.techTitle, .techOrg {
  display: inline;
}

.separator {
  display: inline;
}

.blackText {
  color: black;
}
