/* Slider */
.events-sliders-section {
  .slick-slide {
    display: flex !important;
    justify-content: center;
    padding: 0 12px !important;
    box-sizing: border-box;
  }
  .slick-slide > div {
    width: 100% !important;
  }
  .slick-slide {
    z-index: -1 !important;
  }
  .slick-active {
    z-index: 1 !important;
  }
}
.events-cards-section,
.reviews-section {
  .slick-slide {
    display: flex !important;
    justify-content: center;
    padding: 5px 12px !important;
    box-sizing: border-box;
  }
  .slick-slide > div {
    width: 100% !important;
  }
  .slick-slide {
    z-index: -1 !important;
  }
  .slick-active {
    z-index: 1 !important;
  }
}
.reviews-section {
  .slick-slide {
    padding: 30px 12px !important;
  }
  .slick-slide > div {
    width: 100% !important;
  }
}

.slider-section {
  .slick-slide {
    padding: 0 12px;
    box-sizing: border-box;
  }
  .slick-dots li button {
    // width: 61px !important;
    // height: 6px !important;
    // padding: 0 !important;
    // border-radius: 24px;
    width: 20px !important;
    height: 15px !important;
    border-radius: 50px !important;
    border: 0;
    background-color: #d9d9d9 !important;
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s !important;
    font-size: 0 !important;
    cursor: pointer;
  }
  .slick-dots li button:hover {
    background-color: var(--main-color) !important;
  }
  .slick-dots li button::before,
  .slick-dots li .slick-active button:before {
    display: none !important;
    // color: var(--main-color) !important;
  }

  .slick-dots li.slick-active button {
    width: 35px !important;
    background-color: var(--main-color) !important;
  }

  .slick-slider {
    position: relative !important;

    display: block !important;
    box-sizing: border-box !important;

    -webkit-user-select: none !important;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none !important;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent !important;
  }

  .slick-list {
    position: relative !important;

    display: block !important;
    overflow: hidden !important;

    margin: 0 !important;
    padding: 0 !important;
  }
  .slick-list:focus {
    outline: none !important;
  }
  .slick-list.dragging {
    cursor: pointer !important;
    cursor: hand !important;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    padding-top: 10px;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;

    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .slick-track:before,
  .slick-track:after {
    display: table !important;

    content: "" !important;
  }
  .slick-track:after {
    clear: both !important;
  }
  .slick-loading .slick-track {
    visibility: hidden !important;
  }

  .slick-slide {
    display: none !important;
    float: left !important;

    height: 100% !important;
    min-height: 1px !important;
  }

  [dir="rtl"] .slick-slide {
    float: right !important;
  }
  .slick-slide img {
    display: block !important;
  }
  .slick-slide.slick-loading img {
    display: none !important;
  }
  .slick-slide.dragging img {
    pointer-events: none !important;
  }
  .slick-initialized .slick-slide {
    display: block !important;
  }
  .slick-loading .slick-slide {
    visibility: hidden !important;
  }
  .slick-vertical .slick-slide {
    display: block !important;

    height: auto !important;

    border: 1px solid transparent !important;
  }
  .slick-arrow.slick-hidden {
    display: none !important;
  }

  .slick-dots {
    text-align: center !important;
    margin-top: 50px !important;
    bottom: -45px !important;
  }
  .slick-dots li {
    display: inline-block !important;
    width: fit-content !important;
    height: fit-content !important;
    margin: 0 9px !important;
  }
  .card-url {
    display: flex !important;
    justify-content: center;
  }
  // @media screen and (max-width: 480px) {
  //     .slick-active{

  //     }
  // }
}
