.container {
  width: 100%;
  padding: 3rem 0 5rem;
}
.header,
.subheader {
  text-align: center;
}

.header {
  color: #ffffff;
  font-size: 3rem;
  font-family: "Futura Bold Oblique", arial;
  margin: auto;
  margin-bottom: 1rem;
}

.subheader {
  font-size: 1.2rem;
  align-self: center;
  /* margin-bottom: 2rem; */
  font-family: "Futura", arial;
  font-weight: 500;
  color: #898ca9;
}

.card a {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 1rem;
  height: 7rem;
  outline: none;
}
.card a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.3s ease-in;
}

@media screen and (max-width: 900px) {
  .header {
    font-size: 2rem;
    text-align: center;
  }
  .subheader {
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 3rem 0 3rem;
  }
}
